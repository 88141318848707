#av-services-app {
  .cdk-virtual-scroll-orientation-horizontal .cdk-virtual-scroll-content-wrapper {
    min-height: 100%;
    min-width: 100%;
  }

  table[mat-table] {
    .table-border-normal {
      box-shadow: inset 1px 1px 0 var(--gray-light), inset 1px -1px 0px var(--gray-light) !important;
    }

    .table-border-last-left-sticky {
      box-shadow: inset -2px 1px 0 var(--gray-light), inset 1px -1px 0px var(--gray-light) !important;
    }

    .table-border-last-right-sticky {
      box-shadow: inset 1px 1px 0 var(--gray-light), inset 2px -1px 0px var(--gray-light) !important;
    }

    th {
      @apply select-none;
    }

    th,
    td {
      @apply border-0 shadow;
      @extend .table-border-normal;

      &:last-child {
        @extend .table-border-last-left-sticky;
      }
    }
  }

  .main-table {
    &.base-table {
      .mat-mdc-header-cell:nth-last-child(2),
      .mdc-data-table__cell:nth-last-child(2) {
        @apply border-r-ui-gray-light;
      }
    }

    &.no-actions-table {
      .mat-mdc-header-cell:nth-last-child(2),
      .mdc-data-table__cell:nth-last-child(2) {
        box-shadow: inset 1px 1px 0 var(--gray-light), inset 1px -1px 0 var(--gray-light),
          inset -1px 0px 0 var(--gray-light) !important;
      }
    }

    .mat-mdc-header-row {
      @apply bg-ui-gray-50 h-8;
    }

    .mdc-data-table__row {
      @apply h-[44px];
    }

    .mat-mdc-header-cell {
      @apply text-ui-gray-100 font-semibold text-sm whitespace-nowrap;
    }

    .mdc-data-table__cell {
      @apply text-ui-dark font-normal text-sm;
    }

    .mat-column-index {
      @apply text-xs text-ui-gray-100 text-center;
    }

    .mdc-data-table__row:last-child .mdc-data-table__cell {
      @apply border-b border-b-ui-gray-light border-solid;
    }

    .mat-mdc-checkbox {
      &.mat-accent .mdc-checkbox--selected ~ .mdc-checkbox__ripple {
        @apply bg-ui-green-150;
      }

      .mdc-checkbox__ripple {
        @apply bg-ui-green-150;
      }

      .mdc-checkbox__ripple * {
        @apply hidden;
      }

      .mdc-checkbox .mdc-checkbox__native-control:enabled ~ .mdc-checkbox__background .mdc-checkbox__checkmark {
        @apply text-white;
      }

      .mdc-checkbox:not(:disabled):active
        .mdc-checkbox__native-control:enabled:not(:checked):not(:indeterminate):not([data-indeterminate='true'])
        ~ .mdc-checkbox__background,
      .mdc-checkbox:not(.mdc-ripple-upgraded):focus
        .mdc-checkbox__native-control:enabled:not(:checked):not(:indeterminate):not([data-indeterminate='true'])
        ~ .mdc-checkbox__background,
      .mdc-checkbox:hover
        .mdc-checkbox__native-control:enabled:not(:checked):not(:indeterminate):not([data-indeterminate='true'])
        ~ .mdc-checkbox__background,
      .mdc-checkbox
        .mdc-checkbox__native-control:enabled:not(:checked):not(:indeterminate):not([data-indeterminate='true'])
        ~ .mdc-checkbox__background {
        @apply border-ui-gray-150;
      }

      .mdc-checkbox:not(:disabled):active
        .mdc-checkbox__native-control:enabled:indeterminate
        ~ .mdc-checkbox__background,
      .mdc-checkbox:not(.mdc-ripple-upgraded):focus
        .mdc-checkbox__native-control:enabled:indeterminate
        ~ .mdc-checkbox__background,
      .mdc-checkbox:not(:disabled):active .mdc-checkbox__native-control:enabled:checked ~ .mdc-checkbox__background,
      .mdc-checkbox:not(.mdc-ripple-upgraded):focus
        .mdc-checkbox__native-control:enabled:checked
        ~ .mdc-checkbox__background,
      .mdc-checkbox:hover .mdc-checkbox__native-control:enabled:indeterminate ~ .mdc-checkbox__background,
      .mdc-checkbox:hover .mdc-checkbox__native-control:enabled:checked ~ .mdc-checkbox__background,
      .mdc-checkbox .mdc-checkbox__native-control:enabled:indeterminate ~ .mdc-checkbox__background,
      .mdc-checkbox .mdc-checkbox__native-control:enabled:checked ~ .mdc-checkbox__background {
        @apply bg-ui-green-light border-ui-green-light;
      }
    }

    .action-icon {
      @apply text-ui-gray-100 text-3xl cursor-pointer inline-flex items-center justify-center mr-1.5;
      &:hover {
        color: var(--dark);
      }

      &:last-child {
        @apply mr-0;
      }
    }
  }

  mat-paginator {
    @apply bg-transparent;

    .mat-mdc-paginator-range-label,
    .mat-mdc-paginator-page-size-label {
      @apply text-xs text-ui-gray-100 font-semibold font-nunito;
    }

    .mat-mdc-select-min-line {
      @apply text-sm text-ui-dark;
    }

    .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
      @apply py-1.5;
    }

    .mat-mdc-select-arrow {
      @apply text-ui-dark;
    }

    .mat-mdc-form-field {
      @apply w-[70px];
      &.mat-focused {
        .mat-mdc-select-arrow {
          @apply text-ui-green-light;
        }
      }
    }

    .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__notch {
      @apply border-ui-gray-light;
    }

    .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
    .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing,
    .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch {
      @apply border-ui-green-light;
    }

    .mat-mdc-tooltip-trigger {
      @apply p-2 h-10 w-10 ml-2;
    }
  }

  //  Table pages styles
  .table-scroll-fix {
    @apply max-h-full flex flex-col;
  }

  app-devices-main-page,
  app-incidents-main-page,
  app-client-management-main-page,
  app-user-management-main-page {
    @apply h-full flex flex-col;
    mat-card {
      @apply flex flex-col max-h-full overflow-hidden;
      mat-card-content {
        @apply flex flex-col flex-grow max-h-full;
        app-devices-table,
        app-clients-table,
        app-users-table,
        app-incidents-table {
          @apply max-h-full flex flex-col flex-grow overflow-hidden;
        }
      }
    }
  }

  .fixed-scroll {
    @apply max-h-[480px] #{!important};
  }

  app-device-management {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  app-manufacturer-list,
  app-model-list {
    @apply block h-full overflow-hidden;
    &[hidden] {
      @apply hidden;
    }
  }
}
