.classic-form-item-wrapper {
  width: 404px;
}

#av-services-app {
  .false-form-input {
    @apply inline-block p-2.5 border rounded-md mr-2 text-xs w-[150px] bg-gray-50 text-ui-gray-100;
  }

  .false-dropdown {
    @apply flex items-center justify-evenly;
    & > div {
      @apply py-0.5 px-3  rounded-full text-2xl flex items-center  bg-gray-50 text-ui-gray-100;
      strong {
        @apply whitespace-nowrap overflow-ellipsis max-w-20 overflow-hidden;
      }
    }
  }

  .input-classic {
    @apply classic-form-item-wrapper;
    .mat-mdc-form-field-bottom-align::before {
      height: 10px;
    }

    .mat-mdc-form-field-focus-overlay {
      @apply bg-transparent;
    }

    &.has-white-bg {
      .mat-mdc-text-field-wrapper {
        @apply bg-white;
      }
    }

    .mdc-line-ripple {
      &::before,
      &::after {
        @apply hidden;
      }
    }

    .mat-mdc-text-field-wrapper {
      @apply bg-transparent rounded-md border border-ui-gray-light px-3;

      &.mdc-text-field--invalid {
        @apply border-ui-error;

        .mat-mdc-form-field-icon-suffix {
          .mat-icon {
            @apply text-ui-error;
          }
        }
      }

      &.mdc-text-field--disabled {
        @apply bg-ui-gray-50;
      }
    }

    .mat-mdc-form-field-icon-suffix {
      @apply p-0;

      .mat-icon {
        @apply text-ui-green-100 p-1.5;
      }
    }

    &.mat-focused {
      .mat-mdc-text-field-wrapper {
        @apply bg-white;
      }
    }

    input {
      @apply text-sm text-ui-dark focus:outline-none;

      &:disabled {
        @apply text-ui-gray-100;
      }
    }

    .mat-mdc-form-field-infix {
      @apply text-sm min-h-0;
    }

    .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-infix {
      @apply py-[7px];
    }

    &.size-small {
      .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-infix {
        @apply py-[5px];
      }
    }

    .mat-mdc-form-field-error-wrapper {
      @apply px-3;
    }

    .mat-mdc-form-field-error {
      @apply text-ui-error text-xs;
    }

    .mat-mdc-form-field-subscript-wrapper {
      @apply h-5;
    }

    &.no-error-message .mat-mdc-form-field-subscript-wrapper {
      @apply hidden;
    }
  }

  .input-classic-simulation {
    @apply classic-form-item-wrapper flex border rounded-md border-ui-gray-light h-10 items-center px-4 font-nunito text-sm text-ui-gray-100 bg-ui-gray-200;
  }

  .select-classic {
    @apply max-w-full classic-form-item-wrapper;
    &.full-width {
      @apply w-full;
    }

    &.rounded-borders {
      &:not(.mat-focused) .mdc-text-field--filled:not(.mdc-text-field--disabled) {
        @apply bg-ui-gray-900;
      }

      .mdc-text-field {
        @apply rounded-full border-ui-gray-900;
      }

      .mat-mdc-select-value-text {
        @apply font-poppins font-semibold text-base #{!important};
      }
    }

    &.mat-focused {
      .mdc-text-field {
        @apply border-ui-green-light;
      }

      .mat-mdc-form-field-focus-overlay {
        @apply opacity-100 bg-transparent;
      }

      .arrow-up {
        @apply inline-block;
      }

      .arrow-down {
        @apply hidden;
      }
    }

    .mdc-line-ripple {
      @apply hidden;
    }

    .mdc-text-field--filled:not(.mdc-text-field--disabled) {
      @apply bg-transparent;
    }

    .mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-form-field-infix {
      @apply cursor-pointer min-h-[38px] py-0 flex items-center w-auto;
    }

    .mdc-text-field {
      @apply border rounded-md border-ui-gray-light p-0;

      &.mdc-text-field--invalid {
        @apply border-ui-error;
      }
    }

    &.mat-mdc-form-field-type-mat-select.mat-form-field-appearance-fill .mat-mdc-floating-label {
      @apply font-nunito text-sm text-ui-dark static transform-none left-0 top-0 max-w-none overflow-clip;
    }

    .mat-mdc-form-field-flex {
      @apply flex items-center justify-between px-3;
    }

    .mat-mdc-form-field-icon-suffix {
      .mat-icon {
        @apply p-0;
      }
    }

    .arrow-up {
      @apply hidden;
    }

    .mat-mdc-select {
      @apply w-auto max-w-full;

      .mat-mdc-select-value-text {
        @apply text-sm;
      }
    }

    .mat-mdc-select-arrow-wrapper {
      @apply hidden;
    }

    .mat-mdc-form-field-subscript-wrapper {
      @apply hidden;
    }

    .mat-mdc-select-placeholder {
      @apply font-nunito text-sm text-ui-gray-100;
    }

    &.has-validation {
      .mat-mdc-form-field-subscript-wrapper {
        @apply block;
      }

      .mat-mdc-form-field-error-wrapper {
        @apply -mt-1 px-3;
      }

      .mat-mdc-form-field-error {
        @apply text-ui-error text-xs;
      }
    }

    &.size-small {
      .mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-form-field-infix {
        @apply min-h-[34px];
      }
    }

    &.gray-bg {
      .mdc-text-field--filled:not(.mdc-text-field--disabled) {
        @apply bg-ui-gray-50;
      }
    }
  }

  .stroked-select-multiple-classic {
    @apply classic-form-item-wrapper;

    &.mat-focused {
      .mdc-text-field {
        @apply border-ui-green-light;
      }

      .mat-mdc-form-field-focus-overlay {
        @apply opacity-100 bg-transparent;
      }

      .arrow-up {
        @apply inline-block;
      }

      .arrow-down {
        @apply hidden;
      }
    }

    .mat-mdc-select:not(.mat-mdc-select-empty) {
      @apply w-0 overflow-hidden;
    }

    .mat-mdc-form-field-subscript-wrapper,
    .mdc-line-ripple {
      @apply hidden;
    }

    .mdc-text-field--filled:not(.mdc-text-field--disabled) {
      @apply bg-transparent;
    }

    .mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-form-field-infix {
      @apply cursor-pointer min-h-[38px] py-0 flex items-center w-auto;
    }

    .mdc-text-field {
      @apply border rounded-md border-ui-gray-light p-0;
    }

    &.mat-mdc-form-field-type-mat-select.mat-form-field-appearance-fill .mat-mdc-floating-label {
      @apply font-nunito text-sm text-ui-dark static transform-none left-0 top-0 max-w-none w-full min-w-[70px];
    }

    .mat-mdc-form-field-flex {
      @apply flex items-center justify-between px-3;
    }

    .mat-mdc-form-field-icon-suffix {
      .mat-icon {
        @apply p-0;
      }
    }

    .arrow-up {
      @apply hidden;
    }

    .mat-mdc-select-arrow-wrapper {
      @apply hidden;
    }

    .mat-mdc-select-placeholder {
      @apply font-nunito text-sm text-ui-gray-100;
      -webkit-text-fill-color: unset;
    }
  }
}

.main-slider-container {
  @apply classic-form-item-wrapper flex justify-between items-start;

  .main-slider-input {
    @apply w-[28px] m-0;

    .slider-input-field {
      @apply font-poppins text-sm font-semibold;

      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
      }
    }
  }

  .mdc-text-field {
    @apply pl-1.5 pr-0;
  }

  .mdc-text-field--filled .mdc-line-ripple::after {
    @apply border-b border-b-ui-green-light;
  }

  .mat-mdc-form-field-infix {
    @apply min-h-[34px] py-1.5 w-4 #{!important};
  }

  .mat-mdc-form-field .mdc-text-field--filled {
    @apply bg-transparent;
  }

  .mat-mdc-form-field-focus-overlay {
    @apply hidden;
  }

  .main-slider {
    @apply w-[358px] m-0;
  }

  .slider-restriction {
    @apply text-ui-gray-100 text-xs font-nunito font-semibold;
  }

  .mat-mdc-slider .mdc-slider__track--inactive {
    @apply opacity-100 bg-ui-gray-light;
  }

  .mat-mdc-slider .mdc-slider__track--active_fill {
    @apply border-ui-green-light;
  }

  .mat-mdc-slider .mdc-slider__thumb:hover .mdc-slider__thumb-knob,
  .mat-mdc-slider .mdc-slider__thumb--focused .mdc-slider__thumb-knob,
  .mat-mdc-slider .mdc-slider__thumb-knob {
    @apply border-ui-green-light bg-ui-green-light;
  }

  .mat-mdc-slider.mdc-slider--disabled {
    @apply opacity-100;
  }

  .mdc-text-field--disabled .mdc-line-ripple::after {
    @apply border-b-ui-gray-150;
  }

  .mat-mdc-slider.mdc-slider--disabled .mdc-slider__track--inactive {
    @apply opacity-100 bg-ui-gray-150;
  }

  .mat-mdc-slider.mdc-slider--disabled .mdc-slider__track--active_fill {
    @apply border-ui-gray-150;
  }

  .mdc-slider--disabled + div .slider-restriction {
    @apply text-ui-gray-150;
  }

  .mat-mdc-slider.mdc-slider--disabled .mdc-slider__thumb-knob {
    @apply border-ui-gray-150 bg-ui-gray-150;
  }

  .mdc-text-field--filled.mdc-text-field--disabled .mdc-text-field__input {
    @apply text-ui-gray-150;
  }

  .mdc-slider__input:disabled {
    @apply cursor-default;
  }
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.main-checkbox {
  .mdc-label {
    @apply font-nunito font-normal text-ui-dark text-sm;
  }
}

.opt-in-checkbox {
  .mdc-label {
    @apply font-poppins font-semibold text-ui-dark text-sm;
  }

  &.mdc-checkbox--disabled {
    .mdc-label {
      @apply text-ui-gray-100 #{!important};
    }
  }
}
